import * as React from "react";
import classnames from "classnames";
import { connect } from "react-redux";

import {
  Upload,
  Calendar,
  Grid,
  Settings,
  Sidebar,
  Sliders,
  Tool,
  Type,
} from "react-feather";
import { toggleTimetableOrientation, toggleTitleDisplay } from "actions/theme";
import { ModuleCode, Semester } from "types/modules";
import {
  SemTimetableConfig,
  SemTimetableMultiConfig,
  TimetableArrangement,
  TimetableGeneratorConfig,
} from "types/timetables";

import elements from "views/elements";
import config from "config";
import ResetTimetable from "./ResetTimetable";
import ShareTimetable, { shareUrl } from "./ShareTimetable";

import styles from "./TimetableActions.scss";
import TimetableGeneratorConfigModal from "views/components/TimetableGenConfModal";

function toSemTimetableConfig(timetableArrangement: TimetableArrangement) {
  const timetable: SemTimetableConfig = {};
  Object.values(timetableArrangement)
    .flat(2)
    .forEach((lesson) => {
      const { moduleCode, lessonType, classNo } = lesson;
      (timetable[moduleCode] ??= {})[lessonType] = classNo;
    });
  return timetable;
}

type Props = {
  optimisedLessons: TimetableArrangement;
  readOnly: boolean;
  semester: Semester;
  multiTimetable: SemTimetableMultiConfig;

  isVerticalOrientation: boolean;
  toggleTimetableOrientation: () => void;

  showTitle: boolean;
  toggleTitleDisplay: () => void;

  showExamCalendar: boolean;
  toggleExamCalendar: () => void;

  hiddenModules: ModuleCode[];

  resetTimetable: () => void;
};

const TimetableActions: React.FC<Props> = (props) => {
  const [isConfigOpen, setIsConfigOpen] = React.useState(false);

  return (
    <div
      className="btn-toolbar justify-content-between"
      role="toolbar"
      aria-label="Timetable utilities"
    >
      <TimetableGeneratorConfigModal
        isOpen={isConfigOpen}
        onClose={() => {
          setIsConfigOpen(false);
        }}
      />
      <div
        className={styles.buttonGroup}
        role="group"
        aria-label="Timetable manipulation"
      >
        <button
          type="button"
          className={classnames("btn btn-outline-primary btn-svg")}
          onClick={props.toggleTimetableOrientation}
          disabled={props.showExamCalendar}
        >
          <Sidebar className={styles.sidebarIcon} />
          {props.isVerticalOrientation ? "Horizontal Mode" : "Vertical Mode"}
        </button>

        {!props.isVerticalOrientation && (
          <button
            type="button"
            className={classnames(
              styles.titleBtn,
              "btn-outline-primary btn btn-svg",
            )}
            onClick={props.toggleTitleDisplay}
            disabled={props.showExamCalendar}
          >
            <Type className={styles.titleIcon} />
            {props.showTitle ? "Hide Titles" : "Show Titles"}
          </button>
        )}

        {config.examAvailabilitySet.has(props.semester) && (
          <button
            type="button"
            className={classnames(
              styles.calendarBtn,
              elements.examCalendarBtn,
              "btn-outline-primary btn btn-svg",
            )}
            onClick={props.toggleExamCalendar}
          >
            {props.showExamCalendar ? (
              <>
                <Grid className="svg svg-small" /> Timetable
              </>
            ) : (
              <>
                <Calendar className="svg svg-small" /> Exam Calendar
              </>
            )}
          </button>
        )}
      </div>

      {
        <div
          className={styles.buttonGroup}
          role="group"
          aria-label="Timetable exporting"
        >
          <button
            type="button"
            className={classnames("btn-outline-primary btn btn-svg")}
            onClick={() => {
              setIsConfigOpen(true);
            }}
          >
            <Tool className={styles.titleIcon} />
            Configuration
          </button>
          <a
            type="button"
            href={shareUrl(
              props.semester,
              toSemTimetableConfig(props.optimisedLessons),
              props.hiddenModules,
            )}
            target="_blank"
            className={classnames("btn-outline-primary btn btn-svg")}
          >
            <Upload className={styles.titleIcon} />
            Export
          </a>
          {!props.readOnly && (
            <ResetTimetable resetTimetable={props.resetTimetable} />
          )}
        </div>
      }
    </div>
  );
};

export default connect(null, {
  toggleTimetableOrientation,
  toggleTitleDisplay,
})(TimetableActions);
